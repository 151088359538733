<template>
  <div :id="`card-input__${idModifier}`" class="card-input"></div> 
  <div 
    class="card-input__error"
    :class="[classModifier]"
    v-show="!inputData.complete && !inputData.empty && inputData.error !== undefined
  ">
    {{ inputData.error?.message }}
  </div> 
</template>

<script>
export default {
  props: {
    inputData: Object,
    idModifier: String,
    classModifier: String
  }
}
</script>